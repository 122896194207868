export default {
  timerControl: [
    {
      total: 4500,
      dashLineAnimate: {},
      textGroupAnimate: {},
      solidlineAnimate: {},
      detail: {
        delay: 1300,
        pointAnimate: {
          sizeRange: [2, 4],
          twinkleDelayRange: [200, 1300],
          pointAnimateDura: 500
        }
      }
    },
    {
      total: 23000,
      dashLineAnimate: {},
      textGroupAnimate: {},
      solidlineAnimate: {},
      detail: {
        contourAnimate: {
          pointAnimate: {
            dura: 300,
            delay: 500,
            startcss: { opacity: 0 },
            endcss: { opacity: 1 }
          },
          solidLine: {
            delay: 1500
          },
          dashLine: {
            delay: 3000
          },
          textGroup: {
            delay: 3500
          },
          tipsAlert: {
            delay: 4500
          },
          total: 7000
        },
        threePartsAnimate: {
          dashLine: {
            delay: 500
          },
          textGroup: {
            delay: 1000
          },
          tipsAlert: {
            delay: 2000
          },
          total: 4500
        },
        fiveEyeAnimate: {
          dashLine: {
            delay: 500
          },
          textGroup: {
            delay: 1000
          },
          tipsAlert: {
            delay: 2000
          },
          total: 4500
        },
        goldenTriangleAnimate: {
          point: {
            dura: 300,
            delay: 1000,
            startcss: { opacity: 0 },
            endcss: { opacity: 1 }
          },
          triangleLine: {
            delay: 2000
          },
          pinkLine: {
            delay: 1500
          },
          triangleText: {
            delay: 2500,
            dura: 500
          },
          dashLine: {
            delay: 3000
          },
          textGroup: {
            delay: 3500
          },
          tipsAlert: {
            delay: 4500
          }
        }
      }
    },
    {
      total: 10000,
      dashLineAnimate: {},
      textGroupAnimate: {},
      solidlineAnimate: {},
      detail: {
        pointAnimate: {
          dura: 1000,
          delay: 1300,
          startcss: { opacity: 0 },
          endcss: { opacity: 1 }
        },
        pinkLineAnimate: {
          dura: 500,
          delay: 2000,
          startcss: { opacity: 0 },
          endcss: { opacity: 1 }
        },
        whiteLineAnimate: {
          dura: 500,
          delay: 2500
        },
        dashLineAnimate: {
          delay: 2500
        },
        textGroupAnimate: {
          delay: 3500
        },
        eyeBrowAniamte: {
          dura: 1000,
          delay: 3000,
          startcss: { opacity: 0 },
          endcss: { opacity: 1 }
        },
        pupilAnimate: {
          dashlineDelay: 4500,
          textDelay: 5500
        },
        tipsAlert: {
          delay: 6000
        }
      }
    },
    {
      total: 10000,
      dashLineAnimate: {},
      textGroupAnimate: {},
      solidlineAnimate: {},
      detail: {
        pointAnimate: {
          dura: 1000,
          delay: 1300,
          startcss: { opacity: 0 },
          endcss: { opacity: 1 }
        },
        pinkLineAnimate: {
          dura: 500,
          delay: 2000,
          startcss: { opacity: 0 },
          endcss: { opacity: 1 }
        },

        whiteLineAnimate: {
          dura: 1000,
          delay: 3000,
          startcss: { opacity: 0 },
          endcss: { opacity: 1 }
        },
        dashlineAnimate: {
          delay: 4000
        },
        textAnimate: {
          delay: 4500
        },
        radius: 0.15,
        tipsAlert: {
          delay: 5500
        }
      }
    }
  ],
  animateConfig: {
    dashLineAnimate: {
      startcss: { opacity: 0 },
      endcss: { opacity: 1 },
      delay: 1000,
      dura: 500
    },
    textGroupAnimate: {
      dura: 1000,
      delay: 1300,
      startcss: { opacity: 0 },
      endcss: { opacity: 1 }
    },
    solidlineAnimate: {
      startcss: { opacity: 0 },
      endcss: { opacity: 1 },
      delay: 1000,
      dura: 500
    },
    arc: {
      radius: 0.2,
      startcss: { opacity: 0 },
      endcss: { opacity: 1 },
      delay: 1000,
      dura: 500
    }
  },
  layoutConfig: {
    dashLineOffset: 0.04
  }
};
