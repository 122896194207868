<template>
  <div class="container" :style="{ zIndex: 3 }">
    <div class="logo">
      <img src="../../../assets/images/logo.png" alt="" />
      <div class="linkResult" @click="linkResult">跳过</div>
    </div>
    <div id="draw-board"></div>
    <div v-if="draw">
      <component
        :number="number"
        :timer="timers"
        :denselandmark="denselandmark"
        :draw="draw"
        :faceRect="faceRect"
        :data="data"
        v-bind:is="currentTabComponent"
        @animateResultRect="animateResultRect"
        @complete="complete"
        @clear="clear"
        @_createGradualLine="_createGradualLine"
        @_createArcWithText="_createArcWithText"
        @_createSolidLine="_createSolidLine"
        @_createDashLine="_createDashLine"
        @makeTextWidthArrowLine="makeTextWidthArrowLine"
      ></component>
    </div>
    <div class="result-container">
      <!-- 1 -->
      <div
        class="result-item animate__animated animate__fadeInUpBig"
        v-if="step === 1 && currentTabComponent === 'AnimateTwo'"
      >
        <div class="wra">
          <div class="title">
            {{
              data.face.face_type
                ? resultLocale[data.face.face_type]
                : resultLocale.cannotDetect
            }}:
          </div>
          <div class="text">
            下颌角度数
            <span class="value">{{ data.face.E || 0 }}°</span>
          </div>
        </div>
      </div>
      <!-- 2 -->
      <div
        class="result-item animate__animated animate__fadeInUpBig"
        v-if="step === 2 && currentTabComponent === 'AnimateTwo'"
      >
        <div class="wra">
          <div class="title">
            {{
              data.three_parts.one_part.faceup_result
                ? resultLocale[data.three_parts.one_part.faceup_result]
                : resultLocale.cannotDetect
            }}
          </div>
        </div>
        <div class="wra">
          <div class="title">
            {{
              data.three_parts.two_part.facemid_result
                ? resultLocale[data.three_parts.two_part.facemid_result]
                : resultLocale.cannotDetect
            }}
          </div>
        </div>
        <div class="wra">
          <div class="title">
            {{
              data.three_parts.three_part.facedown_result
                ? resultLocale[data.three_parts.three_part.facedown_result]
                : resultLocale.cannotDetect
            }}
          </div>
          <div class="text">
            三庭比例
            <span class="value">
              {{ data.three_parts.parts_ratio || 0 }}
            </span>
          </div>
        </div>
      </div>
      <!-- 3 -->
      <div
        class="result-item animate__animated animate__fadeInUpBig"
        v-if="step === 3 && currentTabComponent === 'AnimateTwo'"
      >
        <div class="wra">
          <div class="title">
            {{
              data.five_eyes.one_eye.righteye_empty_result
                ? resultLocale[data.five_eyes.one_eye.righteye_empty_result]
                : resultLocale.cannotDetect
            }}
          </div>
          <div class="title">
            {{
              data.five_eyes.three_eye.eyein_result
                ? resultLocale[data.five_eyes.three_eye.eyein_result]
                : resultLocale.cannotDetect
            }}
          </div>
        </div>
        <div class="wra">
          <div class="title">
            {{
              data.five_eyes.five_eye.lefteye_empty_result
                ? resultLocale[data.five_eyes.five_eye.lefteye_empty_result]
                : resultLocale.cannotDetect
            }}
          </div>
          <div class="text">
            五眼比例
            <span class="value">
              {{ data.five_eyes.eyes_ratio || 0 }}
            </span>
          </div>
          <div class="text-pink">
            最佳比例
            <span class="value">
              0.8 : 1 : 1.2 : 1 : 0.8
            </span>
          </div>
        </div>
      </div>
      <!-- 4 -->
      <div
        class="result-item animate__animated animate__fadeInUpBig"
        v-if="step === 4 && currentTabComponent === 'AnimateTwo'"
      >
        <div class="wra">
          <div class="title">黄金三角度数:</div>
          <div class="text">{{ data.golden_triangle || 0 }}°</div>
        </div>
        <div class="wra">
          <div class="title">
            {{
              data.jaw.jaw_type
                ? resultLocale[data.jaw.jaw_type]
                : resultLocale.cannotDetect
            }}
          </div>
          <div class="text">
            下巴角度
            <span class="value"> {{ data.face.E || 0 }}° </span>
          </div>
        </div>
      </div>
      <!-- 5 -->
      <div
        class="result-item animate__animated animate__fadeInUpBig"
        v-if="step === 5 && currentTabComponent === 'AnimateThree'"
      >
        <div class="wra">
          <div class="title">
            {{ resultLocale[data.eyebrow.eyebrow_type || "connotDetect"] }}:
          </div>
          <div class="text">
            眉毛挑度
            <span class="value">
              {{ data.eyebrow.brow_uptrend_angle || 0 }}°
            </span>
          </div>
          <div class="text">
            眉毛弯度
            <span class="value">
              {{ data.eyebrow.brow_camber_angle || 0 }}°
            </span>
          </div>
        </div>
        <div class="wra">
          <div class="title">
            {{ resultLocale[data.eyes.eyes_type || "connotDetect"] }}:
          </div>
          <div class="text">
            内眦角度数
            <span class="value">
              {{ data.eyes.angulus_oculi_medialis || 0 }}°
            </span>
          </div>
        </div>
      </div>
      <!-- 6 -->
      <div
        class="result-item animate__animated animate__fadeInUpBig"
        v-if="step === 6"
      >
        <div class="wra">
          <div class="title">
            {{ resultLocale[data.nose.nose_type || "connotDetect"] }}:
          </div>
          <div class="text">
            鼻翼宽度
            <span class="value"> {{ data.nose.nose_width || 0 }}mm </span>
          </div>
        </div>
        <div class="wra">
          <div class="title">
            {{ resultLocale[data.mouth.mouth_type || "connotDetect"] }}:
          </div>
          <div class="text">
            嘴唇厚度
            <span class="value"> {{ data.mouth.lip_thickness || 0 }}mm </span>
          </div>
          <div class="text">
            嘴角弯曲度
            <span class="value"> {{ data.mouth.angulus_oris || 0 }}° </span>
          </div>
        </div>
      </div>
    </div>
    <div class="logoName">
      <img src="../../../assets/images/name.png" alt="" />
    </div>
  </div>
</template>
<script>
import AnimateOne from "./AnimateOne";
import AnimateTwo from "./AnimateTwo";
import AnimateThree from "./AnimateThree";
import AnimateFour from "./AnimateFour";
import { extendPoints } from "@/utils/imageHandler";
import {
  makeDashLine,
  makePath,
  makeArrowLine,
  getDrawingBoard,
  makeGradualLine,
  makeTextGroup,
  makeArc,
  calculateTextGroupHeight
} from "@/components/TwinklePointAndPathAnimate/element.js";
import animateControl from "./animateControl.js";
import { clearTimeoutTimers } from "@/utils/index";
import themeConfig from "../theme.config";
import { resultLocale } from "@/utils/result.js";
const fontsize = document.documentElement.clientWidth / 3.6;
export default {
  data() {
    return {
      draw: undefined,
      step: 0,
      timers: [],
      currentTabComponent: "AnimateOne",
      arrowColor: null,
      animateCfg: null,
      resultLocale: resultLocale
    };
  },
  props: {
    number: {
      type: Number
    },
    denselandmark: {
      type: Object
    },
    faceRect: {
      type: Object
    },
    theme: {
      type: String
    },
    data: {
      type: Object
    }
  },
  watch: {
    number: {
      handler(newName) {
        switch (newName) {
          case 0:
            this.currentTabComponent = "AnimateOne";
            break;
          case 1:
            this.currentTabComponent = "AnimateTwo";
            break;
          case 2:
            this.currentTabComponent = "AnimateThree";
            break;
          case 3:
            this.currentTabComponent = "AnimateFour";
            break;
          default:
            this.currentTabComponent = "AnimateOne";
        }
        const { timerControl, animateConfig } = animateControl;
        let animate = Object.assign(
          {},
          animateConfig.textGroupAnimate,
          timerControl[newName].textGroupAnimate
        );
        this.arrowColor = themeConfig[this.theme].arrowLineColor;
        this.animateCfg = animate;
        const timer = setTimeout(() => {
          if (localStorage.getItem("testType") == 2) {
            this.linkResult();
            return;
          }
          this.$emit("complete", newName);
          this.draw.clear();
        }, timerControl[newName].total);
        this.timers.push(timer);
      },
      immediate: true
    }
  },
  components: {
    AnimateOne,
    AnimateTwo,
    AnimateThree,
    AnimateFour
  },
  mounted() {
    let draw = getDrawingBoard("#draw-board");
    this.draw = draw;
  },
  destroyed() {
    this.draw.clear();
    clearTimeoutTimers(this.timers);
  },
  methods: {
    linkResult() {
      // eslint-disable-next-line no-undef
      wx.miniProgram.redirectTo({
        url: `/pages/faceresult/index?uuid=${localStorage.getItem("uuid")}&type=${localStorage.getItem(
          "testType"
        )}`
      });
    },
    makeTextWidthArrowLine(
      draw,
      startPoint,
      endPoint,
      text,
      vertical,
      animateTimer
    ) {
      let timer = Object.assign(
        {},
        { dura: 500, delay: 1000 },
        animateTimer || {}
      );
      let group = makeArrowLine(draw, startPoint, endPoint, {
        color:
          vertical && vertical.arrowColor
            ? vertical.arrowColor
            : this.arrowColor,
        vertical: vertical ? vertical.vertical === "vertical" : false
      });
      const textHeight = calculateTextGroupHeight(text);
      if (vertical && vertical.vertical === "vertical") {
        let centerX = startPoint.x - (vertical.offset || fontsize * 0.3);
        let centerY =
          Math.abs((startPoint.y + endPoint.y) / 2) - textHeight / 8;
        if (vertical.writePosition && vertical.writePosition === "right") {
          centerX = startPoint.x + (vertical.offset || fontsize * 0.3);
        }
        group.add(makeTextGroup(draw, text, { x: centerX, y: centerY }));
      } else {
        const centerX = Math.abs((startPoint.x + endPoint.x) / 2);
        let centerY = startPoint.y + 0.1 * fontsize;
        if (
          vertical &&
          vertical.writePosition &&
          vertical.writePosition === "top"
        ) {
          centerY = centerY - textHeight;
        }
        group.add(makeTextGroup(draw, text, { x: centerX, y: centerY }));
      }
      return group
        .css(this.animateCfg.startcss)
        .animate(timer.dura, timer.delay)
        .css(this.animateCfg.endcss);
    },
    complete(step, draw) {
      this.$emit("complete", step, draw);
    },
    _createDashLine(pathList, delay, dura, color) {
      const { timerControl, animateConfig } = animateControl;
      let animate = Object.assign(
        {},
        animateConfig.dashLineAnimate,
        timerControl[this.number].dashLineAnimate
      );
      for (let paths of pathList) {
        makeDashLine(this.draw, paths, {
          color: color || themeConfig[this.theme].defaultDashLineColor
        })
          .css(animate.startcss)
          .animate(
            dura ? dura : animate.dura || 0,
            delay ? delay : animate.delay || 0
          )
          .css(animate.endcss);
      }
    },
    _createSolidLine(paths, delay, dura, color, size) {
      const { timerControl, animateConfig } = animateControl;
      let animate = Object.assign(
        {},
        animateConfig.solidlineAnimate,
        timerControl[this.number].solidlineAnimate
      );
      for (let path of paths) {
        makePath(this.draw, path, {
          color: color || themeConfig[this.theme].defaultDashLineColor,
          size
        })
          .css(animate.startcss)
          .animate(
            dura ? dura : animate.dura || 0,
            delay ? delay : animate.delay || 0
          )
          .css(animate.endcss);
      }
    },
    _createGradualLine(paths, length, delay, dura, opt) {
      const { timerControl, animateConfig } = animateControl;
      let animate = Object.assign(
        {},
        animateConfig.solidlineAnimate,
        timerControl[this.number].solidlineAnimate
      );

      for (let path of paths) {
        let p1 = extendPoints(path[0], path[1], length);
        let p2 = extendPoints(path[1], path[0], length);
        if (!p1 || !p2) {
          return;
        }
        // makePoint(this.state.draw, p1, {size: 3, color: 'red'})
        // makePoint(this.state.draw, p2, {size: 3, color: 'yellow'})
        makeGradualLine(this.draw, [p1, p2], {
          color:
            opt && opt.color
              ? opt.color
              : themeConfig[this.theme].gradualLineColor,
          size: opt && opt.size ? opt.size : 2
        })
          .css(animate.startcss)
          .animate(
            dura ? dura : animate.dura || 0,
            delay ? delay : animate.delay || 0
          )
          .css(animate.endcss);
      }
    },
    _createArcWithText(angle, text, textOffset, angleOpt, textOpt) {
      let angleAnimate = Object.assign(
        {},
        animateControl.animateConfig.arc,
        angleOpt
      );
      let textAnimate = Object.assign(
        {},
        animateControl.animateConfig.arc,
        textOpt
      );
      const arc = makeArc(this.draw, angle, angleAnimate.radius);
      if (arc) {
        arc
          .attr({
            stroke: themeConfig[this.theme].defaultShadeLineColor,
            fill: "transparent",
            "stroke-dasharray": "2",
            "stroke-width": angleAnimate.size || 2
          })
          .css(angleAnimate.startcss)
          .animate(angleAnimate.dura, angleAnimate.delay)
          .css(angleAnimate.endcss);
      }
      makeTextGroup(this.draw, text, {
        x: angle.center.x + textOffset.x,
        y: angle.center.y + textOffset.y
      })
        .css(textAnimate.startcss)
        .animate(textAnimate.dura, textAnimate.delay)
        .css(textAnimate.endcss);
    },
    clear() {
      this.step = 0;
      this.draw.clear();
    },
    animateResultRect(step, delay) {
      let timer = setTimeout(() => {
        this.step = step;
      }, delay);
      this.timers.push(timer);
    }
  }
};
</script>
<style lang="less" scoped>
#draw-board {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  .logo {
    width: 100%;
    height: 35px;
    position: fixed;
    left: 0;
    top: 15px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    img {
      width: 140px;
      height: 100%;
      vertical-align: middle;
    }
    .linkResult {
      width: 60px;
      height: 100%;
      text-align: right;
      line-height: 35px;
      font-size: 16px;
      color: rgba(255, 255, 255, 1);
      text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.6);
      
    }
  }
  .logoName {
    width: 70px;
    height: 16px;
    position: fixed;
    right: 15px;
    bottom: 49px;
    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }
  .result-container {
    position: absolute;
    width: 264px;
    left: 17px;
    bottom: 85px;
    .result-item {
      width: 260px;
      min-height: 117px;
      padding-left: 17px;
      padding-top: 27px;
      padding-bottom: 15px;
      box-sizing: border-box;
      background: url("../../../assets/images/resultbg.png") no-repeat;
      background-size: 100% 100%;
      color: #fff;
      .wra {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: rgba(255, 255, 255, 1);
        letter-spacing: 1px;
        flex-wrap: wrap;
        .title {
          margin-right: 10px;
        }
        .value {
          font-size: 12px;
          line-height: initial;
        }
      }
    }
  }
}
</style>
