
export const faceKeys = [
  "contour_chin",
  "contour_left1",
  "contour_left2",
  "contour_left3",
  "contour_left4",
  "contour_left5",
  "contour_left6",
  "contour_left7",
  "contour_left8",
  "contour_left9",
  "contour_left10",
  "contour_left11",
  "contour_left12",
  "contour_left13",
  "contour_left14",
  "contour_left15",
  "contour_left16",
  "contour_right1",
  "contour_right2",
  "contour_right3",
  "contour_right4",
  "contour_right5",
  "contour_right6",
  "contour_right7",
  "contour_right8",
  "contour_right9",
  "contour_right10",
  "contour_right11",
  "contour_right12",
  "contour_right13",
  "contour_right14",
  "contour_right15",
  "contour_right16",
  "left_eye_bottom",
  "left_eye_center",
  "left_eye_left_corner",
  "left_eye_lower_left_quarter",
  "left_eye_lower_right_quarter",
  "left_eye_pupil",
  "left_eye_right_corner",
  "left_eye_top",
  "left_eye_upper_left_quarter",
  "left_eye_upper_right_quarter",
  "left_eyebrow_left_corner",
  "left_eyebrow_lower_left_quarter",
  "left_eyebrow_lower_middle",
  "left_eyebrow_lower_right_corner",
  "left_eyebrow_lower_right_quarter",
  "left_eyebrow_upper_left_quarter",
  "left_eyebrow_upper_middle",
  "left_eyebrow_upper_right_corner",
  "left_eyebrow_upper_right_quarter",
  "mouth_left_corner",
  "mouth_lower_lip_bottom",
  "mouth_lower_lip_left_contour1",
  "mouth_lower_lip_left_contour2",
  "mouth_lower_lip_left_contour3",
  "mouth_lower_lip_right_contour1",
  "mouth_lower_lip_right_contour2",
  "mouth_lower_lip_right_contour3",
  "mouth_lower_lip_top",
  "mouth_right_corner",
  "mouth_upper_lip_bottom",
  "mouth_upper_lip_left_contour1",
  "mouth_upper_lip_left_contour2",
  "mouth_upper_lip_left_contour3",
  "mouth_upper_lip_left_contour4",
  "mouth_upper_lip_right_contour1",
  "mouth_upper_lip_right_contour2",
  "mouth_upper_lip_right_contour3",
  "mouth_upper_lip_right_contour4",
  "mouth_upper_lip_top",
  "nose_bridge1",
  "nose_bridge2",
  "nose_bridge3",
  "nose_left_contour1",
  "nose_left_contour2",
  "nose_left_contour3",
  "nose_left_contour4",
  "nose_left_contour5",
  "nose_middle_contour",
  "nose_right_contour1",
  "nose_right_contour2",
  "nose_right_contour3",
  "nose_right_contour4",
  "nose_right_contour5",
  "nose_tip",
  "right_eye_bottom",
  "right_eye_center",
  "right_eye_left_corner",
  "right_eye_lower_left_quarter",
  "right_eye_lower_right_quarter",
  "right_eye_pupil",
  "right_eye_right_corner",
  "right_eye_top",
  "right_eye_upper_left_quarter",
  "right_eye_upper_right_quarter",
  "right_eyebrow_lower_left_corner",
  "right_eyebrow_lower_left_quarter",
  "right_eyebrow_lower_middle",
  "right_eyebrow_lower_right_quarter",
  "right_eyebrow_right_corner",
  "right_eyebrow_upper_left_corner",
  "right_eyebrow_upper_left_quarter",
  "right_eyebrow_upper_middle",
  "right_eyebrow_upper_right_quarter"
];
export const winkleKeys = [
  "right_eyebrow_34",
  "right_eyebrow_0",
  "right_eyebrow_55",
  "left_eyebrow_31",
  "left_eyebrow_12",
  "left_eyebrow_2",
  "right_eye_eyelid_0",
  "right_eye_eyelid_31",
  "left_eye_eyelid_63",
  "left_eye_eyelid_10",
  "left_eye_eyelid_18",
  "face_contour_right_60",
  "face_contour_left_60",
  "face_contour_right_36",
  "face_contour_left_36",
  "face_contour_right_0",
  "face_contour_left_15",
  "face_hairline_72",
  "nose_midline_53",
  "nose_right_39",
  "upper_lip_0",
  "upper_lip_32",
  "lower_lip_46",
  "lower_lip_16",
  "lower_lip_7"
];

export const eyebrowUpperLineKeys = [
  "left_eyebrow_0",
  "left_eyebrow_5",
  "left_eyebrow_12",
  "left_eyebrow_23",
  "left_eyebrow_31",
  "left_eyebrow_34"
];
export const eyebrowLowerLineKeys = [
  "left_eyebrow_0",
  "left_eyebrow_55",
  "left_eyebrow_42",
  "left_eyebrow_34"
];
export const eyebrowHorizontalDashLineKeys = [
  "left_eyebrow_12",
  "left_eyebrow_55",
  "left_eyebrow_34"
];

export const eyebrowKeys = Array.from(
  new Set([...eyebrowUpperLineKeys, ...eyebrowLowerLineKeys])
);
export const eyelidUpperLineKeys = [
  "left_eye_eyelid_0",
  "left_eye_eyelid_8",
  "left_eye_eyelid_16",
  "left_eye_eyelid_24",
  "left_eye_eyelid_31"
];
export const eyelidLowerLineKeys = [
  "left_eye_eyelid_31",
  "left_eye_eyelid_38",
  "left_eye_eyelid_52",
  "left_eye_eyelid_0"
];

export const eyelidKeys = Array.from(
  new Set([...eyelidUpperLineKeys, ...eyelidLowerLineKeys])
);
export const eyePointsKey = eyebrowKeys.concat(eyelidKeys);

export const allowEyeRect = {
  width: 1.34,
  height: 0.9,
  top: 1.6,
  left: 1.57
};

export const noseKeys = [
  "nose_right_0",
  "nose_right_27",
  "nose_right_39",
  "nose_right_51",
  "nose_midline_53",
  "nose_left_52",
  "nose_left_40",
  "nose_left_28",
  "nose_left_0"
];

export const upperliplineKeys1 = [
  "upper_lip_0",
  "upper_lip_6",
  "upper_lip_12",
  "upper_lip_16",
  "upper_lip_20",
  "upper_lip_27",
  "upper_lip_33"
];
export const upperliplineKeys2 = [
  "upper_lip_0",
  "upper_lip_56",
  "upper_lip_49",
  "upper_lip_42",
  "upper_lip_33"
];

export const lowerliplineKeys1 = [
  "upper_lip_0",
  "lower_lip_53",
  "lower_lip_46",
  "lower_lip_39",
  "upper_lip_33"
];

export const lowerliplineKeys2 = [
  "upper_lip_0",
  "lower_lip_7",
  "lower_lip_14",
  "lower_lip_18",
  "lower_lip_25",
  "upper_lip_33"
];

export const lipKeys = Array.from(
  new Set([
    ...upperliplineKeys1,
    ...upperliplineKeys2,
    ...lowerliplineKeys1,
    ...lowerliplineKeys2
  ])
);

export const animateTimelineCfg = {
  dashLine: {
    dura: 300,
    delay: 300,
    startcss: { opacity: 0 },
    endcss: { opacity: 1 }
  },
  arrow: {
    dura: 1000,
    delay: 1300,
    startcss: { opacity: 0 },
    endcss: { opacity: 1 }
  }
};

export const contourPaths = [
  ["face_contour_right_49", "face_contour_right_36"],
  ["face_contour_right_0", "face_contour_right_36"]
];

export const mainFacePointsKey = {
  top: "face_hairline_72",
  right: "face_hairline_0",
  left: "face_hairline_144",
  bottom: "face_contour_right_0"
};

export const otherNeededPoint = [
  "face_hairline_17",
  "face_hairline_127",
  "nose_midline_0",
  "nose_midline_8",
  "face_hairline_132",
  "face_hairline_12",
  "left_eye_pupil_center",
  "right_eye_pupil_center",
  "face_contour_right_15",
  "lower_lip_15",
  "left_eye_pupil_radius",
  "left_eye_eyelid_46",
  "face_hairline_144",
  "nose_midline_49"
];
let flatFacePointKeys = Object.entries(mainFacePointsKey).map(item => item[1]);
let flatContourPaths = [...contourPaths[0], ...contourPaths[1]];
const totalPoints = [
  ...noseKeys,
  ...lipKeys,
  ...eyePointsKey,
  ...winkleKeys,
  ...flatContourPaths,
  ...flatFacePointKeys,
  ...otherNeededPoint
];
export const neededPoint = Array.from(new Set(totalPoints));
