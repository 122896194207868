const pink = {
  // twinklePointsColor: ["rgba(255,255,255,.5)", "rgba(255,125,186,.5)"],
  twinklePointsColor: ["rgba(255,255,255,.5)", "#68EAE5"],
  pathsColor: ["rgba(255,255,255,.5)"],
  // arrowLineColor: "#FF7DBA",
  arrowLineColor: "#68EAE5",
  defaultDashLineColor: "rgba(255,255,255,.6)",
  defaultShadeLineColor: "rgba(255,255,255,.6)",
  gradualLineColor: "#fff"
};

export default {
  pink
};
