<template>
  <div></div>
</template>
<script>
import { makePoint } from "@/components/TwinklePointAndPathAnimate/element.js";
import themeConfig from "../theme.config.js";
import { contourPaths } from "../const.js";
import animateControl from "./animateControl.js";
const fs = document.documentElement.clientWidth / 3.6;
export default {
  data() {
    return {
      theme: "pink"
    };
  },
  mounted() {
    this.initAnimate();
  },
  props: {
    number: {
      type: Number
    },
    denselandmark: {
      type: Object
    },
    timers: {
      type: Array,
      default: function() {
        return [];
      }
    },
    draw: {
      type: Object
    },
    data: {
      type: Object
    },
    faceRect: {}
  },
  methods: {
    initAnimate() {
      const delayBase = 300;
      const {
        contourAnimate,
        threePartsAnimate,
        fiveEyeAnimate,
        goldenTriangleAnimate
      } = animateControl.timerControl[1].detail;
      // step 1
      let timer1 = setTimeout(() => {
        this.createContourPathsAnimate();
        this.drawFaceRectDashLine();
        this.$emit("animateResultRect", 1, contourAnimate.tipsAlert.delay);
      }, delayBase);
      // step 2
      let timer2 = setTimeout(() => {
        this.$emit("clear");
        this.drawThreeParts();
        this.$emit("animateResultRect", 2, threePartsAnimate.tipsAlert.delay);
      }, contourAnimate.total + delayBase);

      // // step 3
      let timer3 = setTimeout(() => {
        this.$emit("clear");
        this.drawFiveEye();
        this.$emit("animateResultRect", 3, fiveEyeAnimate.tipsAlert.delay);
      }, contourAnimate.total + threePartsAnimate.total + delayBase);

      // // step 4
      let timer4 = setTimeout(() => {
        this.$emit("clear");
        this.drawGoldenTriangle();
        this.$emit(
          "animateResultRect",
          4,
          goldenTriangleAnimate.tipsAlert.delay
        );
      }, contourAnimate.total + fiveEyeAnimate.total + threePartsAnimate.total + delayBase);
      this.timers.concat([timer1, timer2, timer3, timer4]);
    },
    createContourPathsAnimate() {
      // 画下颔角角度
      const { arrowLineColor } = themeConfig[this.theme];
      const radius = animateControl.animateConfig.arc.radius * fs;
      const {
        pointAnimate,
        solidLine
      } = animateControl.timerControl[1].detail.contourAnimate;
      let pathArr = [];
      for (let item of contourPaths) {
        let paths = [];
        for (let key of item) {
          paths.push(this.denselandmark[key]);
        }
        pathArr.push(paths);
      }
      for (let key of [
        "face_contour_right_49",
        "face_contour_right_36",
        "face_contour_right_0"
      ]) {
        makePoint(this.draw, this.denselandmark[key], {
          size: 0.04 * fs,
          color: arrowLineColor
        })
          .css(pointAnimate.startcss)
          .animate(pointAnimate.dura, pointAnimate.delay)
          .css(pointAnimate.endcss);
      }
      // 角度实线
      this.$emit("_createGradualLine", pathArr, radius, solidLine.delay);
      // 画圆弧
      this.$emit(
        "_createArcWithText",
        {
          start: this.denselandmark.face_contour_right_0,
          center: this.denselandmark.face_contour_right_36,
          end: this.denselandmark.face_contour_right_49
        },
        `${this.data.face.E || 0}°`,
        { x: -radius * 1.5, y: -radius * 1.5 },
        { delay: solidLine.delay, radius },
        { delay: solidLine.delay }
      );
    },

    drawFaceRectDashLine() {
      const {
        dashLine,
        textGroup
      } = animateControl.timerControl[1].detail.contourAnimate;

      // 人脸框虚线
      const overLength = 0.4 * fs;
      const lineTop = [
        { x: this.faceRect.x - overLength, y: this.faceRect.y },
        {
          x: this.faceRect.x + this.faceRect.width + overLength,
          y: this.faceRect.y
        }
      ];
      const lineLeft = [
        { x: this.faceRect.x, y: this.faceRect.y - overLength },
        {
          x: this.faceRect.x,
          y: this.faceRect.y + this.faceRect.height + overLength
        }
      ];
      const lineRight = [
        {
          x: this.faceRect.x + this.faceRect.width,
          y: this.faceRect.y - overLength
        },
        {
          x: this.faceRect.x + this.faceRect.width,
          y: this.faceRect.y + this.faceRect.height + overLength
        }
      ];
      const lineBottom = [
        {
          x: this.faceRect.x - overLength,
          y: this.faceRect.y + this.faceRect.height
        },
        {
          x: this.faceRect.x + this.faceRect.width + overLength,
          y: this.faceRect.y + this.faceRect.height
        }
      ];
      // 下颌角虚线
      const offsetTop = 0.33 * fs;
      const offsetBottom = 0.4 * fs;
      const lineLeftShort = [
        {
          x: this.denselandmark.face_contour_left_36.x,
          y: this.denselandmark.face_contour_left_36.y - offsetTop
        },
        {
          x: this.denselandmark.face_contour_left_36.x,
          y: this.denselandmark.face_contour_left_36.y + offsetBottom
        }
      ];
      const lineRightShort = [
        {
          x: this.denselandmark.face_contour_right_36.x,
          y: this.denselandmark.face_contour_right_36.y - offsetTop
        },
        {
          x: this.denselandmark.face_contour_right_36.x,
          y: this.denselandmark.face_contour_right_36.y + offsetBottom
        }
      ];
      // 颧骨左右虚线
      const offsetLength = 0.35 * fs;
      const lineLeft1 = [
        {
          x: this.denselandmark.face_hairline_17.x,
          y: this.faceRect.y - offsetLength
        },
        {
          x: this.denselandmark.face_hairline_17.x,
          y: this.faceRect.y + this.faceRect.height + offsetLength
        }
      ];
      const lineRight1 = [
        {
          x: this.denselandmark.face_hairline_127.x,
          y: this.faceRect.y - offsetLength
        },
        {
          x: this.denselandmark.face_hairline_127.x,
          y: this.faceRect.y + this.faceRect.height + offsetLength
        }
      ];

      this.$emit(
        "_createDashLine",
        [
          lineTop,
          lineLeft,
          lineRight,
          lineBottom,
          lineLeftShort,
          lineRightShort,
          lineLeft1,
          lineRight1
        ],
        dashLine.delay
      );
      // console.log(this.makeTextWidthArrowLine, 333333);
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,
        {
          x: this.faceRect.x - 0.08 * fs,
          y: this.faceRect.y
        },
        {
          x: this.faceRect.x - 0.08 * fs,
          y: this.faceRect.y + this.faceRect.height
        },
        `脸部长度\n${this.data.face.face_length}mm`,
        {
          vertical: "vertical"
        },
        { delay: textGroup.delay }
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,
        this.denselandmark.face_contour_left_36,
        {
          x: this.denselandmark.face_contour_right_36.x,
          y: this.denselandmark.face_contour_left_36.y
        },
        `下颌角宽度\n${this.data.face.mandible_length}mm`,
        {},
        { delay: textGroup.delay }
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,

        {
          x: this.denselandmark.face_hairline_127.x,
          y: this.denselandmark.face_hairline_17.y
        },
        this.denselandmark.face_hairline_17,
        `颞部宽度\n${this.data.face.tempus_length}mm`,
        {},
        { delay: textGroup.delay }
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,
        this.denselandmark.face_contour_left_60,
        {
          x: this.denselandmark.face_contour_right_60.x,
          y: this.denselandmark.face_contour_left_60.y
        },
        `颧骨宽度\n${this.data.face.zygoma_length}mm`,
        {},
        { delay: textGroup.delay }
      );
    },
    drawThreeParts() {
      const {
        dashLine,
        textGroup
      } = animateControl.timerControl[1].detail.threePartsAnimate;
      const offsetX = fs * 0.1;
      const leftX = this.faceRect.x - offsetX;
      const rightX = this.faceRect.x + this.faceRect.width + offsetX;
      const line1 = [
        { x: leftX, y: this.faceRect.y },
        { x: rightX, y: this.faceRect.y }
      ];
      const line2 = [
        { x: leftX, y: this.denselandmark.nose_midline_8.y },
        { x: rightX, y: this.denselandmark.nose_midline_8.y }
      ];
      const line3 = [
        { x: leftX, y: this.denselandmark.nose_midline_53.y },
        { x: rightX, y: this.denselandmark.nose_midline_53.y }
      ];
      const line4 = [
        { x: leftX, y: this.denselandmark.face_contour_right_0.y },
        { x: rightX, y: this.denselandmark.face_contour_right_0.y }
      ];
      const line5 = [
        { x: this.denselandmark.nose_midline_53.x, y: this.faceRect.y },
        {
          x: this.denselandmark.nose_midline_53.x,
          y: this.faceRect.y + this.faceRect.height
        }
      ];
      this.$emit(
        "_createDashLine",
        [line1, line2, line3, line4, line5],
        dashLine.delay
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,
        line1[0],
        line2[0],
        `上庭\n${this.data.three_parts.one_part.faceup_length}mm`,
        {
          vertical: "vertical"
        },
        { delay: textGroup.delay }
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,
        line2[0],
        line3[0],
        `中庭\n${this.data.three_parts.two_part.facemid_length}mm`,
        {
          vertical: "vertical"
        },
        { delay: textGroup.delay }
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,
        line3[0],
        line4[0],
        `下庭\n${this.data.three_parts.three_part.facedown_length}mm`,
        {
          vertical: "vertical"
        },
        { delay: textGroup.delay }
      );
    },
    drawFiveEye() {
      const {
        dashLine,
        textGroup
      } = animateControl.timerControl[1].detail.fiveEyeAnimate;
      const offset = 0.1 * fs;
      const offsetArrowY = 0.5 * fs;
      const topY = this.faceRect.y - offset;
      const bottomY = this.faceRect.y + this.faceRect.height + offset;
      const keys = [
        "face_hairline_132",
        "left_eye_eyelid_0",
        "left_eye_eyelid_31",
        "right_eye_eyelid_31",
        "right_eye_eyelid_0",
        "face_hairline_12"
      ];
      let paths = [];
      for (let key of keys) {
        paths.push([
          {
            x: this.denselandmark[key].x,
            y: topY
          },
          {
            x: this.denselandmark[key].x,
            y: bottomY
          }
        ]);
      }
      this.$emit("_createDashLine", paths, dashLine.delay);

      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,
        { x: paths[0][0].x, y: this.denselandmark.left_eye_eyelid_0.y },
        {
          x: paths[1][0].x,
          y: this.denselandmark.left_eye_eyelid_0.y
        },
        `右外眼角颧弓留白\n${this.data.five_eyes.one_eye.righteye_empty_length}mm`,
        {},
        textGroup
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,

        {
          x: paths[1][0].x,
          y: this.denselandmark.left_eye_eyelid_31.y + offsetArrowY
        },
        {
          x: paths[2][0].x,
          y: this.denselandmark.left_eye_eyelid_31.y + offsetArrowY
        },
        `右眼宽度\n${this.data.five_eyes.righteye}mm`,
        {},
        textGroup
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,

        {
          x: paths[2][0].x,
          y: this.denselandmark.left_eye_eyelid_0.y
        },
        {
          x: paths[3][0].x,
          y: this.denselandmark.left_eye_eyelid_0.y
        },
        `内眼角间距\n${this.data.five_eyes.three_eye.eyein_length}mm`,
        {},
        textGroup
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,

        {
          x: paths[3][0].x,
          y: this.denselandmark.left_eye_eyelid_31.y + offsetArrowY
        },
        {
          x: paths[4][0].x,
          y: this.denselandmark.left_eye_eyelid_31.y + offsetArrowY
        },
        `左眼宽度\n${this.data.five_eyes.lefteye}mm`,
        {},
        textGroup
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,

        {
          x: paths[4][0].x,
          y: this.denselandmark.left_eye_eyelid_0.y
        },
        {
          x: paths[5][0].x,
          y: this.denselandmark.left_eye_eyelid_0.y
        },
        `左外眼角颧弓留白\n${this.data.five_eyes.five_eye.lefteye_empty_length}mm`,
        {},
        textGroup
      );
    },
    drawGoldenTriangle() {
      const { radius } = animateControl.animateConfig.arc;
      const {
        triangleLine,
        triangleText,
        point,
        dashLine,
        textGroup,
        pinkLine
      } = animateControl.timerControl[1].detail.goldenTriangleAnimate;
      const keys = [
        "left_eye_pupil_center",
        "right_eye_pupil_center",
        "nose_midline_53",
        "face_contour_right_15",
        "face_contour_left_15",
        "lower_lip_15",
        "face_contour_right_0"
      ];
      for (let key of keys) {
        makePoint(this.draw, this.denselandmark[key], {
          size: 0.04 * fs,
          color: themeConfig[this.theme].arrowLineColor
        })
          .css(point.startcss)
          .animate(point.dura, point.delay)
          .css(point.endcss);
      }
      // 黄金三角
      const linePink = [
        this.denselandmark.left_eye_pupil_center,
        this.denselandmark.right_eye_pupil_center
      ];
      const line1 = [
        this.denselandmark.nose_midline_53,
        this.denselandmark.left_eye_pupil_center
      ];
      const line2 = [
        this.denselandmark.right_eye_pupil_center,
        this.denselandmark.nose_midline_53
      ];
      const r = radius * fs;

      this.$emit(
        "_createArcWithText",
        {
          start: this.denselandmark.left_eye_pupil_center,
          center: this.denselandmark.nose_midline_53,
          end: this.denselandmark.right_eye_pupil_center
        },
        `黄金三角\n${this.data.golden_triangle || 0}°`,
        { x: 0, y: -r * 2.5 },
        { delay: triangleText.delay, radius: r },
        { delay: triangleText.delay }
      );
      this.$emit("_createGradualLine", [line1, line2], r, triangleLine.delay);

      this.$emit(
        "_createSolidLine",
        [linePink],
        pinkLine.delay,
        undefined,
        themeConfig[this.theme].arrowLineColor
      );
      const contourLineWidth = 0.8 * fs;
      const contourLineHeight = 1 * fs;
      // 水平线
      const dashLine1 = [
        {
          x: this.denselandmark.face_contour_left_15.x - contourLineWidth / 2,
          y: this.denselandmark.face_contour_right_0.y
        },
        {
          x: this.denselandmark.face_contour_right_15.x + contourLineWidth / 2,
          y: this.denselandmark.face_contour_right_0.y
        }
      ];
      const dashLine2 = [
        {
          x: this.denselandmark.face_contour_left_15.x - contourLineWidth / 2,
          y: this.denselandmark.lower_lip_15.y
        },
        {
          x: this.denselandmark.face_contour_right_15.x + contourLineWidth / 2,
          y: this.denselandmark.lower_lip_15.y
        }
      ];
      // 垂直线
      const dashLine3 = [
        {
          x: this.denselandmark.face_contour_right_15.x,
          y: this.denselandmark.face_contour_right_15.y - contourLineHeight / 2
        },
        {
          x: this.denselandmark.face_contour_right_15.x,
          y: this.denselandmark.face_contour_right_15.y + contourLineHeight / 3
        }
      ];
      const dashLine4 = [
        {
          x: this.denselandmark.face_contour_left_15.x,
          y: this.denselandmark.face_contour_left_15.y - contourLineHeight / 2
        },
        {
          x: this.denselandmark.face_contour_left_15.x,
          y: this.denselandmark.face_contour_left_15.y + contourLineHeight / 3
        }
      ];
      const shadelineLeft = [
        this.denselandmark.face_contour_right_0,
        this.denselandmark.face_contour_right_15
      ];
      const shadelineRight = [
        this.denselandmark.face_contour_right_0,
        this.denselandmark.face_contour_left_15
      ];
      // 下巴角度

      this.$emit(
        "_createArcWithText",
        {
          start: this.denselandmark.face_contour_left_15,
          center: this.denselandmark.face_contour_right_0,
          end: this.denselandmark.face_contour_right_15
        },
        `${this.data.face.E || 0}°`,
        { x: 0, y: -r * 1.5 },
        { delay: triangleText.delay, radius: r },
        { delay: triangleText.delay }
      );

      this.$emit(
        "_createGradualLine",
        [shadelineLeft, shadelineRight],
        r,
        triangleLine.delay
      );

      this.$emit(
        "_createDashLine",
        [dashLine1, dashLine2, dashLine3, dashLine4],
        dashLine.delay
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,
        {
          x: this.denselandmark.face_contour_left_15.x - contourLineWidth / 2,
          y: this.denselandmark.lower_lip_15.y
        },
        {
          x: this.denselandmark.face_contour_left_15.x - contourLineWidth / 2,
          y: this.denselandmark.face_contour_right_0.y
        },
        `下巴长度\n${this.data.jaw.jaw_length}mm`,
        { vertical: "vertical" },
        textGroup
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,
        {
          x: this.denselandmark.face_contour_left_15.x,
          y: this.denselandmark.face_contour_left_15.y + contourLineHeight / 3
        },
        {
          x: this.denselandmark.face_contour_right_15.x,
          y: this.denselandmark.face_contour_left_15.y + contourLineHeight / 3
        },
        `下巴宽度\n${this.data.jaw.jaw_width}mm`,
        {},
        textGroup
      );
    }
  }
};
</script>
<style lang="less" scoped></style>
