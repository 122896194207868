export const resultLocale = {
  normal_nose: "标准鼻",
  thick_nose: "宽鼻",
  thin_nose: "窄鼻",
  round_eyes: "圆眼",
  thin_eyes: "细长眼",
  big_eyes: "大眼",
  small_eyes: "小眼",
  normal_eyes: "标准眼",
  bushy_eyebrows: "粗眉",
  eight_eyebrows: "八字眉",
  raise_eyebrows: "上挑眉",
  straight_eyebrows: "一字眉",
  round_eyebrows: "拱形眉",
  arch_eyebrows: "柳叶眉",
  thin_eyebrows: "细眉",
  flat_jaw: "圆下巴",
  sharp_jaw: "尖下巴",
  square_jaw: "方下巴",
  pointed_face: "瓜子脸",
  oval_face: "椭圆脸",
  diamond_face: "菱形脸",
  pear_shape_face: "梨形脸",
  round_face: "圆形脸",
  long_face: "长形脸",
  square_face: "方形脸",
  normal_face: "标准脸",
  thin_lip: "薄唇",
  thick_lip: "厚唇",
  smile_lip: "微笑唇",
  upset_lip: "态度唇",
  normal_lip: "标准唇",

  faceup_normal: "上庭标准",
  faceup_long: "上庭偏大",
  faceup_short: "上庭偏小",
  facemid_normal: "中庭标准",
  facemid_long: "中庭偏大",
  facemid_short: "中庭偏小",
  facedown_normal: "下庭标准",
  facedown_long: "下庭偏大",
  facedown_short: "下庭偏小",

  righteye_empty_normal: "五眼右侧适中",
  righteye_empty_short: "五眼右侧偏窄",
  righteye_empty_long: "五眼右侧偏宽",
  eyein_normal: "内眼角间距适中",
  eyein_short: "内眼角间距偏窄",
  eyein_long: "内眼角间距偏宽",
  lefteye_empty_normal: "五眼左侧适中",
  lefteye_empty_short: "五眼左侧偏窄",
  lefteye_empty_long: "五眼左侧偏宽",

  cannotDetect: "无法判断"
};
