import { SVG } from "@svgdotjs/svg.js";
// eslint-disable-next-line no-unused-vars
import { Point, getFontSize, extendPoints } from "@/utils/imageHandler";

// const fs = getFontSize();
const fs = document.documentElement.clientWidth / 3.6;
const textLineHeight = 0.14 * fs;
const textFontSize = 0.1 * fs;

// export interface SVGPoint {
//     color: string
//     size: number
// }

// export interface SVGPath {
//     color?: string
//     size?: number
// }

export function getDrawingBoard(ele, width, height) {
  let _width = width || "100%";
  let _height = height || "100%";
  return SVG()
    .addTo(ele)
    .size(_width, _height);
}

export function makePoint(draw, position, opt) {
  return draw
    .circle(opt.size)
    .attr({
      fill: opt.color || "rgba(255,255,255,0.5)",
      stroke: opt.color || "rgba(255,255,255,0.5)"
    })
    .move(position.x - opt.size / 2, position.y - opt.size / 2);
}

export function makePath(draw, paths, opt) {
  let pathstr = "";
  paths.map((item, i) => {
    let p = `${item.x} ${item.y}`;
    if (i === 0) {
      pathstr += `M ${p} `;
    } else {
      pathstr += `L ${p} `;
    }
    return p;
  });
  return draw.path(pathstr).attr({
    stroke: opt && opt.color ? opt.color : "rgba(255,255,255,0.5)",
    fill: "transparent",
    "stroke-width": opt && opt.size ? opt.size : 1
  });
}

export function makeDashLine(draw, paths, opt) {
  let path = makePath(draw, paths, opt);
  path.css("stroke-dasharray", "2");
  return path;
}

export function makeDashLineGroup(draw, paths, opt) {
  let l = [];
  for (let path of paths) {
    const line = makeDashLine(draw, path, opt);
    l.push(line);
  }
  return l;
}

export function makeArrowLine(draw, startPoint, endPoint, opt) {
  const triangleWidth = 5;
  const triangleHeight = 5;
  let startTrianglePoints = [
    { x: startPoint.x, y: startPoint.y },
    {
      x: startPoint.x + triangleWidth,
      y: startPoint.y - triangleHeight / 2
    },
    {
      x: startPoint.x + triangleWidth,
      y: startPoint.y + triangleHeight / 2
    }
  ];
  let endTrianglePoints = [
    { x: endPoint.x, y: endPoint.y },
    { x: endPoint.x - triangleWidth, y: endPoint.y - triangleHeight / 2 },
    { x: endPoint.x - triangleWidth, y: endPoint.y + triangleHeight / 2 }
  ];
  let centerPath = [
    { x: startPoint.x + triangleWidth, y: startPoint.y },
    { x: endPoint.x - triangleWidth, y: endPoint.y }
  ];
  let group = draw.group();

  if (opt.vertical) {
    startTrianglePoints = [
      { x: startPoint.x, y: startPoint.y },
      {
        x: startPoint.x - triangleWidth / 2,
        y: startPoint.y + triangleHeight
      },
      {
        x: startPoint.x + triangleWidth / 2,
        y: startPoint.y + triangleHeight
      }
    ];
    endTrianglePoints = [
      { x: endPoint.x, y: endPoint.y },
      {
        x: endPoint.x - triangleWidth / 2,
        y: endPoint.y - triangleHeight
      },
      {
        x: endPoint.x + triangleWidth / 2,
        y: endPoint.y - triangleHeight
      }
    ];
    centerPath = [startPoint, endPoint];
  }

  for (let item of [startTrianglePoints, endTrianglePoints]) {
    group.add(makeTriangle(draw, item, opt.color));
  }
  group.add(makePath(draw, centerPath, { color: opt.color }));
  return group;
}

export function makeTriangle(draw, points, color) {
  return makePath(draw, points, { color }).attr({ fill: color });
}

export function makeTextGroup(draw, text, centerPoint) {
  // console.log(draw, 1);
  // console.log(text, 2);
  // console.log(centerPoint, 3);
  let textArr = text.split("\n");
  let group = draw.group();
  let textSVG = draw.text(function(add) {
    for (let i in textArr) {
      add
        .tspan(textArr[i])
        .move(centerPoint.x, centerPoint.y)
        .newLine();
    }
  });
  textSVG.move(centerPoint.x, centerPoint.y).attr({
    fill: "#fff",
    family: "PingFangSC",
    "font-size": textFontSize + "px",
    style: "dominant-baseline:middle;text-anchor:middle;"
  });
  group.add(textSVG);
  return group;
}

export function calculateTextGroupHeight(text) {
  const length = text.split("\n").length;
  return length * textFontSize + (length - 1) * textLineHeight;
}

export function createTextWidthArrowLine(arrowColor, animateCfg) {
  console.log("sadasdas");
  return function makeTextWidthArrowLine(
    draw,
    startPoint,
    endPoint,
    text,
    vertical,
    animateTimer
  ) {
    let timer = Object.assign(
      {},
      { dura: 500, delay: 1000 },
      animateTimer || {}
    );
    let group = makeArrowLine(draw, startPoint, endPoint, {
      color: vertical && vertical.arrowColor ? vertical.arrowColor : arrowColor,
      vertical: vertical ? vertical.vertical === "vertical" : false
    });
    const textHeight = calculateTextGroupHeight(text);
    if (vertical && vertical.vertical === "vertical") {
      let centerX = startPoint.x - (vertical.offset || fs * 0.3);
      let centerY = Math.abs((startPoint.y + endPoint.y) / 2) - textHeight / 8;
      if (vertical.writePosition && vertical.writePosition === "right") {
        centerX = startPoint.x + (vertical.offset || fs * 0.3);
      }
      group.add(makeTextGroup(draw, text, { x: centerX, y: centerY }));
    } else {
      const centerX = Math.abs((startPoint.x + endPoint.x) / 2);
      let centerY = startPoint.y + 0.1 * fs;
      if (
        vertical &&
        vertical.writePosition &&
        vertical.writePosition === "top"
      ) {
        centerY = centerY - textHeight;
      }
      group.add(makeTextGroup(draw, text, { x: centerX, y: centerY }));
    }
    return group
      .css(animateCfg.startcss)
      .animate(timer.dura, timer.delay)
      .css(animateCfg.endcss);
  };
}

export function makeArc(draw, angle, r) {
  let startPoint = extendPoints(angle.start, angle.center, -r);
  let endPoint = extendPoints(angle.end, angle.center, -r);
  if (!startPoint || !endPoint) {
    return null;
  }
  // makePoint(draw, startPoint, { size: 0.01 * fs, color: '#10f34b4a' })
  // makePoint(draw, endPoint, { size: 0.01 * fs, color: '#10f34b4a' })
  // makePoint(draw, angle.center, { size: 0.04 * fs, color: '#ff00006b' })
  return draw.path(
    `M ${startPoint.x} ${startPoint.y} A ${r} ${r} 0 0 1 ${endPoint.x} ${endPoint.y}`
  );
}

export function makeGradualLine(draw, path, opt) {
  var gradient = draw.gradient("linear", function(add) {
    add.stop({ offset: 0, color: "#fff", opacity: 0 });
    add.stop({
      offset: 0.5,
      color: "#fff",
      opacity: 1
    });
    add.stop({
      offset: 1,
      color: "#fff",
      opacity: 0
    });
  });
  return makePath(draw, path, {
    color: gradient.url(),
    size: opt && opt.size ? opt.size : 2
  });
}
