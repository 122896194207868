<template>
  <div id="svg-container"></div>
</template>
<script>
import { makePoint } from "@/components/TwinklePointAndPathAnimate/element.js";
import { random } from "@/utils/imageHandler.js";
import animateControl from "./animateControl.js";
import themeConfig from "../theme.config.js";
import { winkleKeys } from "../const";
export default {
  data() {
    return {
      // timer: []
      theme: "pink"
    };
  },
  props: {
    number: {
      type: Number
    },

    denselandmark: {
      type: Object
    },
    timers: {
      type: Array,
      default: function() {
        return [];
      }
    },
    draw: {
      type: Object
    }
  },
  created() {
    this.initAnimate();
  },
  methods: {
    initAnimate() {
      let timer1 = setTimeout(() => {
        this.createPointsAnimate();
      }, animateControl.timerControl[this.number].detail.delay);
      this.timers.push(timer1);
    },
    createPointsAnimate() {
      const {
        sizeRange,
        twinkleDelayRange,
        pointAnimateDura
      } = animateControl.timerControl[this.number].detail.pointAnimate;
      const { twinklePointsColor } = themeConfig[this.theme];
      for (let key of winkleKeys) {
        const color = random([0, twinklePointsColor.length - 1], "int");
        const size = random(sizeRange, "int");
        const delay = random(twinkleDelayRange, "int");
        let timer = setTimeout(() => {
          makePoint(this.draw, this.denselandmark[key], {
            size,
            color: twinklePointsColor[color]
          })
            .css("opacity", 0)
            .animate(pointAnimateDura, delay)
            .loop(1, true)
            .css("opacity", 1)
            .animate(pointAnimateDura, delay)
            .css("opacity", 0);
        }, random(twinkleDelayRange, "int"));
        this.timers.push(timer);
      }
    }
  }
};
</script>
