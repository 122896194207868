<template>
  <div class="container">
    <div class="img-container">
      <img :src="src" alt="" class="user-pic" key="faceImg" id="faceImg" />
    </div>
    <div class="mask"></div>
    <animate-wra
      v-if="animateStep > -1"
      :faceRect="faceRect"
      :denselandmark="formatdenselandmark"
      theme="pink"
      :number="animateStep"
      @complete="onAnimateComplete"
      :data="data"
    >
    </animate-wra>
  </div>
</template>
<script>
import animateWra from "./WithAnimate.vue";
import {
  neededPoint,
  eyePointsKey,
  allowEyeRect,
  mainFacePointsKey
} from "../const";
import {
  getNaturalSize,
  calculateDevicePoint,
  getCenterPoint,
  getRotatedPoint,
  getRatio,
  scale,
  getAllowFaceRect
} from "@/utils/imageHandler";
import { clearTimeoutTimers } from "@/utils/index";
const fs = document.documentElement.clientWidth / 3.6;
export default {
  data() {
    return {
      formatdenselandmark: null,
      faceRect: null,
      animateStep: -1,
      data: {},
      headpose: null,
      denselandmark: null,
      imageNaturalSize: {},
      src: "",
      toastVisible: false,
      offset: {
        top: 0,
        left: 0
      },
      timers: [],
      allowFaceRect: {
        width: 1.9,
        height: 2.7
      }
    };
  },
  components: {
    animateWra
  },
  created() {
    const imgSrc = this.$store.state.imgSrc;
    const result = this.$store.state.result;
    this.data = result.result;
    this.denselandmark = result.denselandmark;
    this.headpose = result.headpose;
    // const { denselandmark, headpose } = result;
    this.src = imgSrc;
    getNaturalSize(this.src).then(imageNaturalSize => {
      this.$store.state.imageNaturalSize = imageNaturalSize;
      this.imageNaturalSize = imageNaturalSize;
      this.onAnimateComplete(-1);
    });
  },

  methods: {
    getAllowFaceRectVue(allowFaceRect) {
      return getAllowFaceRect(allowFaceRect);
    },
    allowEyeRectVue() {
      return allowEyeRect;
    },
    fitRectToDevice(
      fitRect,
      allowFitRect,
      flattenLandmark,
      imageNaturalSize,
      alfa
    ) {
      const fontsize = fs;
      const picCenter = getCenterPoint(imageNaturalSize);

      const ratio = getRatio(fitRect, {
        width: allowFitRect.width * fontsize,
        height: allowFitRect.height * fontsize
      });
      const fitRectCenter = fitRect.center;
      const fitRectRotateCenter = getRotatedPoint(
        fitRectCenter,
        alfa,
        picCenter
      );
      const fitRectRotateScaleCenter = scale(
        fitRectRotateCenter,
        ratio,
        picCenter
      );
      const deviceRectCenter = getCenterPoint({
        width: allowFitRect.width * fontsize,
        height: allowFitRect.height * fontsize,
        x: allowFitRect.left * fontsize,
        y: allowFitRect.top * fontsize
      });

      const offset = {
        top: deviceRectCenter.y - fitRectRotateScaleCenter.y,
        left: deviceRectCenter.x - fitRectRotateScaleCenter.x
      };
      this.offset = offset;
      let imgCenterPoint = calculateDevicePoint(
        picCenter,
        picCenter,
        picCenter,
        alfa,
        ratio,
        offset
      );
      const formatdenselandmark = this.formatdenselandmarkFun(
        flattenLandmark,
        picCenter,
        picCenter,
        alfa,
        ratio,
        offset
      );
      const fitRectPoint1 = {
        y: formatdenselandmark[mainFacePointsKey.top].y,
        x: formatdenselandmark[mainFacePointsKey.left].x
      };
      const fitRectPoint2 = {
        y: formatdenselandmark[mainFacePointsKey.bottom].y,
        x: formatdenselandmark[mainFacePointsKey.right].x
      };
      let imgLeftTopTargetPoint = calculateDevicePoint(
        { x: 0, y: 0 },
        picCenter,
        picCenter,
        alfa,
        ratio,
        offset
      );
      let imgOffset = calculateDevicePoint(
        imgLeftTopTargetPoint,
        imgCenterPoint,
        imgCenterPoint,
        -alfa,
        1,
        { left: 0, top: 0 }
      );
      this.formatdenselandmark = formatdenselandmark;
      this.faceRect = {
        ...fitRectPoint1,
        width: fitRectPoint2.x - fitRectPoint1.x,
        height: fitRectPoint2.y - fitRectPoint1.y
      };
      let el = document.querySelector("#faceImg");
      if (el) {
        el.style.transform = `rotateZ(${alfa}deg)`;
        el.style.width = `${imageNaturalSize.width * ratio}px`;
        el.style.top = `${imgOffset.y}px`;
        el.style.left = `${imgOffset.x}px`;
        el.style.margin = 0;
      }
    },
    animateTimer(step) {
      this.animateStep = step;
    },
    componentWillUnmount() {
      clearTimeoutTimers(this.timers);
    },
    getFaceRect(denselandmark) {
      let mainFacePoints = {
        top: denselandmark.face[mainFacePointsKey.top],
        right: denselandmark.face[mainFacePointsKey.right],
        left: denselandmark.face[mainFacePointsKey.left],
        bottom: denselandmark.face[mainFacePointsKey.bottom]
      };
      let faceRect = {
        width: Math.sqrt(
          Math.pow(mainFacePoints.left.x - mainFacePoints.right.x, 2) +
            Math.pow(mainFacePoints.left.y - mainFacePoints.right.y, 2)
        ),
        height: Math.sqrt(
          Math.pow(mainFacePoints.top.y - mainFacePoints.bottom.y, 2) +
            Math.pow(mainFacePoints.top.x - mainFacePoints.bottom.x, 2)
        ),
        x:
          mainFacePoints.top.x +
          (mainFacePoints.left.x -
            (mainFacePoints.top.x +
              mainFacePoints.left.x +
              mainFacePoints.right.x +
              mainFacePoints.bottom.x) /
              4),
        y:
          mainFacePoints.top.y +
          (mainFacePoints.left.y -
            (mainFacePoints.top.y +
              mainFacePoints.left.y +
              mainFacePoints.right.y +
              mainFacePoints.bottom.y) /
              4),
        center: {
          x:
            (mainFacePoints.top.x +
              mainFacePoints.left.x +
              mainFacePoints.right.x +
              mainFacePoints.bottom.x) /
            4,
          y:
            (mainFacePoints.top.y +
              mainFacePoints.left.y +
              mainFacePoints.right.y +
              mainFacePoints.bottom.y) /
            4
        }
      };
      return {
        faceRect,
        mainFacePoints
      };
    },
    getEyeRect(denselandmark) {
      let top = eyePointsKey[0],
        left = eyePointsKey[0],
        bottom = eyePointsKey[0],
        right = eyePointsKey[0];
      for (let key of eyePointsKey) {
        if (denselandmark[key].y < denselandmark[top].y) {
          top = key;
        }
        if (denselandmark[key].x < denselandmark[left].x) {
          left = key;
        }
        if (denselandmark[key].y > denselandmark[bottom].y) {
          bottom = key;
        }
        if (denselandmark[key].x > denselandmark[right].x) {
          right = key;
        }
      }
      const mainEyePoints = {
        top: denselandmark[top],
        left: denselandmark[left],
        bottom: denselandmark[bottom],
        right: denselandmark[right]
      };
      let eyeRect = {
        width: Math.sqrt(
          Math.pow(mainEyePoints.left.x - mainEyePoints.right.x, 2) +
            Math.pow(mainEyePoints.left.y - mainEyePoints.right.y, 2)
        ),
        height: Math.sqrt(
          Math.pow(mainEyePoints.top.y - mainEyePoints.bottom.y, 2) +
            Math.pow(mainEyePoints.top.x - mainEyePoints.bottom.x, 2)
        ),
        x:
          mainEyePoints.top.x +
          (mainEyePoints.left.x -
            (mainEyePoints.top.x +
              mainEyePoints.left.x +
              mainEyePoints.right.x +
              mainEyePoints.bottom.x) /
              4),
        y:
          mainEyePoints.top.y +
          (mainEyePoints.left.y -
            (mainEyePoints.top.y +
              mainEyePoints.left.y +
              mainEyePoints.right.y +
              mainEyePoints.bottom.y) /
              4),
        center: {
          x:
            (mainEyePoints.top.x +
              mainEyePoints.left.x +
              mainEyePoints.right.x +
              mainEyePoints.bottom.x) /
            4,
          y:
            (mainEyePoints.top.y +
              mainEyePoints.left.y +
              mainEyePoints.right.y +
              mainEyePoints.bottom.y) /
            4
        }
      };
      return {
        eyeRect,
        mainEyePoints
      };
    },
    flattenDenseLandmark(denselandmark) {
      let newLandmark = {};
      for (let item of Object.entries(denselandmark)) {
        newLandmark = Object.assign({}, newLandmark, item[1]);
      }
      newLandmark = Object.assign(newLandmark, {
        left_eye_pupil_radius: {
          x:
            denselandmark.left_eye.left_eye_pupil_center.x -
            denselandmark.left_eye.left_eye_pupil_radius,
          y: denselandmark.left_eye.left_eye_pupil_center.y
        }
      });

      return newLandmark;
    },
    formatdenselandmarkFun(
      flattenLandmark,
      faceRotatedOffsetCenter,
      picCenter,
      alfa,
      ratio,
      offset
    ) {
      const { denselandmark } = this.$store.state.result;
      let formatedlandmark = {};
      for (let key of neededPoint) {
        formatedlandmark[key] = calculateDevicePoint(
          flattenLandmark[key],
          faceRotatedOffsetCenter,
          picCenter,
          alfa,
          ratio,
          offset
        );
      }
      formatedlandmark.left_eye_pupil_radius_number =
        denselandmark.left_eye.left_eye_pupil_radius;
      return formatedlandmark;
    },
    goto(link, type) {
      console.log(link), console.log(type);
    },
    saveUserImagePosition() {
      let el = document.querySelector("#faceImg");
      if (el) {
        let style = {};
        for (let item of el.style.cssText.split("; ")) {
          const [name, value] = item.split(": ");
          style[name] = value.replace(";", "");
        }
        this.$store.state.imageDisplayStyle = style;
      }
    },
    onAnimateComplete(stage) {
      const nextStage = stage + 1;
      const denselandmark = this.denselandmark;
      const headpose = this.headpose;
      // 图片原尺寸
      const imageNaturalSize = this.imageNaturalSize;
      // 
      let flattenLandmark = this.flattenDenseLandmark(denselandmark);
      const alfa = 0 - headpose.roll_angle;
      const { faceRect } = this.getFaceRect(denselandmark);
      if ([0, 3].includes(nextStage)) {
        this.fitRectToDevice(
          faceRect,
          getAllowFaceRect(this.allowFaceRect),
          flattenLandmark,
          imageNaturalSize,
          alfa
        );
        this.saveUserImagePosition();
      }
      if (nextStage === 2) {
        const { eyeRect } = this.getEyeRect(flattenLandmark);
        this.fitRectToDevice(
          eyeRect,
          allowEyeRect,
          flattenLandmark,
          imageNaturalSize,
          alfa
        );
      }
      if (nextStage === 4) {
        // eslint-disable-next-line no-undef
        wx.miniProgram.redirectTo({
          url: `/pages/faceresult/index?uuid=${localStorage.getItem("uuid")}&type=${localStorage.getItem(
            "testType"
          )}`
        });
        // this.goto("/facial-features/result", "replace");
        return;
      }
      this.animateTimer(nextStage);
    },
    onCloseToast() {
      this.setState(
        {
          toastVisible: false
        },
        () => {
          this.goto("/facial-features/ready", "replace");
        }
      );
    }
  }
};
</script>
<style lang="less">
.container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.img-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #6e7582;
  overflow: hidden;
  img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    transition: all 1.5s;
  }
}
.mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 1);
  opacity: 0.7;
  z-index: 9999;
  background-color: rgba(29, 12, 84, 0.35);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  -webkit-animation: index_loadImg__2vrdg 1s 1.5s forwards;
  animation: index_loadImg__2vrdg 1s 1.5s forwards;
  z-index: 2;
}

@keyframes index_loadImg__2vrdg {
  0% {
    background-image: none;
  }

  to {
    background-image: url("../../../assets/biaochi.png");
  }
}
</style>
