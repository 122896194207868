<template>
  <div></div>
</template>
<script>
import {
  makePoint,
  makePath
} from "@/components/TwinklePointAndPathAnimate/element.js";
import themeConfig from "../theme.config.js";
import {
  noseKeys,
  lipKeys,
  upperliplineKeys1,
  upperliplineKeys2,
  lowerliplineKeys1,
  lowerliplineKeys2
} from "../const.js";
import animateControl from "./animateControl.js";

const fs = document.documentElement.clientWidth / 3.6;
export default {
  data() {
    return {
      theme: "pink"
    };
  },
  props: {
    number: {
      type: Number
    },
    denselandmark: {
      type: Object
    },
    timers: {
      type: Array,
      default: function() {
        return [];
      }
    },
    draw: {
      type: Object
    },
    data: {
      type: Object
    },
    faceRect: {}
  },
  mounted() {
    this.initAnimate();
  },
  methods: {
    initAnimate() {
      this.drawPoints();
      this.drawPinkLine();
      this.drawNose();
      this.drawLip();
      this.$emit(
        "animateResultRect",
        6,
        animateControl.timerControl[3].detail.tipsAlert.delay
      );
    },
    drawPoints() {
      const { pointAnimate } = animateControl.timerControl[3].detail;
      for (let key of [...noseKeys, ...lipKeys]) {
        makePoint(this.draw, this.denselandmark[key], {
          size: 0.04 * fs,
          color: themeConfig[this.theme].arrowLineColor
        })
          .css(pointAnimate.startcss)
          .animate(pointAnimate.dura, pointAnimate.delay)
          .css(pointAnimate.endcss);
      }
    },
    drawPinkLine() {
      const { pinkLineAnimate } = animateControl.timerControl[3].detail;
      this.createPath(noseKeys, undefined, undefined, pinkLineAnimate);
      this.createPath(upperliplineKeys1, undefined, undefined, pinkLineAnimate);
      this.createPath(upperliplineKeys2, undefined, undefined, pinkLineAnimate);
      this.createPath(lowerliplineKeys1, undefined, undefined, pinkLineAnimate);
      this.createPath(lowerliplineKeys2, undefined, undefined, pinkLineAnimate);
    },
    drawNose() {
      const {
        dashlineAnimate,
        textAnimate
      } = animateControl.timerControl[3].detail;
      const left = "nose_left_40";
      const right = "nose_right_39";
      const bottom = "nose_midline_53";
      const top = "nose_right_0";
      const offsetTop = 0.7 * fs;
      const offsetBottom = 0.05 * fs;
      const offsetX = 0.4 * fs;
      // 水平横线
      const dashline1 = [
        {
          x: this.denselandmark[left].x - offsetX,
          y: this.denselandmark[bottom].y
        },
        {
          x: this.denselandmark[right].x + offsetX,
          y: this.denselandmark[bottom].y
        }
      ];
      // 垂直虚线
      const dashline2 = [
        {
          x: this.denselandmark[left].x,
          y: this.denselandmark[top].y - offsetTop
        },
        {
          x: this.denselandmark[left].x,
          y: this.denselandmark[bottom].y + offsetBottom
        }
      ];
      const dashline3 = [
        {
          x: this.denselandmark[right].x,
          y: this.denselandmark[top].y - offsetTop
        },
        {
          x: this.denselandmark[right].x,
          y: this.denselandmark[bottom].y + offsetBottom
        }
      ];
      this.$emit(
        "_createDashLine",
        [dashline1, dashline2, dashline3],
        dashlineAnimate.delay
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,
        {
          x: this.denselandmark[left].x,
          y: this.denselandmark[top].y - offsetTop / 2
        },
        {
          x: this.denselandmark[right].x,
          y: this.denselandmark[top].y - offsetTop / 2
        },
        `鼻翼宽度\n${this.data.nose.nose_width}mm`,
        undefined,
        textAnimate
      );
    },
    drawLip() {
      const {
        dashlineAnimate,
        textAnimate,
        radius,
        whiteLineAnimate
      } = animateControl.timerControl[3].detail;
      let top = lipKeys[0];
      let left = lipKeys[0];
      let bottom = lipKeys[0];
      let right = lipKeys[0];
      for (let key of lipKeys) {
        if (this.denselandmark[key].y < this.denselandmark[top].y) {
          top = key;
        }
        if (this.denselandmark[key].x < this.denselandmark[left].x) {
          left = key;
        }
        if (this.denselandmark[key].y > this.denselandmark[bottom].y) {
          bottom = key;
        }
        if (this.denselandmark[key].x > this.denselandmark[right].x) {
          right = key;
        }
      }
      const offsetTop = 0.1 * fs;
      const offsetBottom = 0.4 * fs;
      const offsetLeft = 0.3 * fs;
      // 水平横线
      const dashline1 = [
        {
          x: this.denselandmark[left].x - offsetLeft,
          y: this.denselandmark[top].y
        },
        {
          x: this.denselandmark[right].x + offsetLeft,
          y: this.denselandmark[top].y
        }
      ];
      const dashline2 = [
        {
          x: this.denselandmark[left].x - offsetLeft,
          y: this.denselandmark[bottom].y
        },
        {
          x: this.denselandmark[right].x + offsetLeft,
          y: this.denselandmark[bottom].y
        }
      ];
      // 垂直虚线
      const dashline3 = [
        {
          x: this.denselandmark[left].x,
          y: this.denselandmark[top].y - offsetTop
        },
        {
          x: this.denselandmark[left].x,
          y: this.denselandmark[bottom].y + offsetBottom
        }
      ];
      const dashline4 = [
        {
          x: this.denselandmark[right].x,
          y: this.denselandmark[top].y - offsetTop
        },
        {
          x: this.denselandmark[right].x,
          y: this.denselandmark[bottom].y + offsetBottom
        }
      ];
      this.$emit(
        "_createDashLine",
        [dashline1, dashline2, dashline3, dashline4],
        dashlineAnimate.delay
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,
        {
          x: this.denselandmark[left].x - offsetLeft,
          y: this.denselandmark[top].y
        },
        {
          x: this.denselandmark[left].x - offsetLeft,
          y: this.denselandmark[bottom].y
        },
        `嘴唇高度\n${this.data.mouth.mouth_height}mm`,
        { vertical: "vertical" },
        textAnimate
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,
        {
          x: this.denselandmark[left].x,
          y: this.denselandmark[bottom].y + offsetBottom / 2
        },
        {
          x: this.denselandmark[right].x,
          y: this.denselandmark[bottom].y + offsetBottom / 2
        },
        `嘴唇宽度\n${this.data.mouth.mouth_width}mm`,
        undefined,
        textAnimate
      );
      const r = radius * fs;
      // 角度
      this.$emit(
        "_createGradualLine",
        [[this.denselandmark.upper_lip_33, this.denselandmark.lower_lip_39]],
        r * 3,
        whiteLineAnimate.delay
      );
      this.$emit(
        "_createArcWithText",
        {
          start: this.denselandmark.lower_lip_39,
          center: this.denselandmark.upper_lip_33,
          end: dashline4[0]
        },
        `${this.data.mouth.angulus_oris || 0}°`,
        { x: -r * 1.5, y: -r },
        { delay: textAnimate.delay, radius: r },
        { delay: textAnimate.delay }
      );
    },
    createPath(keys, color, size, animate) {
      let paths = [];
      for (let key of keys) {
        paths.push(this.denselandmark[key]);
      }
      let path = makePath(this.draw, paths, {
        color: color || themeConfig[this.theme].arrowLineColor,
        size: size || 0.01 * fs
      }).attr({
        fill: "transparent"
      });
      if (animate) {
        path
          .css(animate.startcss)
          .animate(animate.dura, animate.delay)
          .css(animate.endcss);
      }
      return paths;
    }
  }
};
</script>
<style lang="less" scoped></style>
