<template>
  <div></div>
</template>
<script>
import {
  makePoint,
  makePath
} from "@/components/TwinklePointAndPathAnimate/element.js";
import themeConfig from "../theme.config.js";
import {
  eyePointsKey,
  eyelidKeys,
  allowEyeRect,
  eyebrowKeys,
  eyebrowUpperLineKeys,
  eyebrowLowerLineKeys,
  eyelidUpperLineKeys,
  eyelidLowerLineKeys,
  eyebrowHorizontalDashLineKeys
} from "../const.js";
import animateControl from "./animateControl.js";
const fs = document.documentElement.clientWidth / 3.6;
export default {
  data() {
    return {
      theme: "pink"
    };
  },
  props: {
    number: {
      type: Number
    },
    denselandmark: {
      type: Object
    },
    timers: {
      type: Array,
      default: function() {
        return [];
      }
    },
    draw: {
      type: Object
    },
    data: {
      type: Object
    },
    faceRect: {}
  },
  mounted() {
    this.initAnimate();
  },
  methods: {
    initAnimate() {
      this.drawPoints();
      this.drawPinkLine();
      this.drawWhiteLine();
      this.drawEyelid();
      this.drawEyeBrowDashLineAndText();
      this.$emit(
        "animateResultRect",
        5,
        animateControl.timerControl[2].detail.tipsAlert.delay
      );
    },
    drawPoints() {
      // const { denselandmark, theme, number } = this.props;
      const { pointAnimate } = animateControl.timerControl[2].detail;
      for (let key of [...eyePointsKey, "left_eye_pupil_center"]) {
        makePoint(this.draw, this.denselandmark[key], {
          size: 0.04 * fs,
          color: themeConfig[this.theme].arrowLineColor
        })
          .css(pointAnimate.startcss)
          .animate(pointAnimate.dura, pointAnimate.delay)
          .css(pointAnimate.endcss);
      }
    },
    drawPinkLine() {
      const { pinkLineAnimate } = animateControl.timerControl[2].detail;
      this.createPath(
        eyebrowUpperLineKeys,
        undefined,
        undefined,
        pinkLineAnimate
      );
      this.createPath(
        eyebrowLowerLineKeys,
        undefined,
        undefined,
        pinkLineAnimate
      );
      this.createPath(
        eyelidUpperLineKeys,
        undefined,
        undefined,
        pinkLineAnimate
      );
      this.createPath(
        eyelidLowerLineKeys,
        undefined,
        undefined,
        pinkLineAnimate
      );
    },
    drawWhiteLine() {
      // const { denselandmark, number } = this.props;
      const { whiteLineAnimate } = animateControl.timerControl[2].detail;
      const l = 0.5 * fs;
      const line1 = [
        this.denselandmark[eyebrowHorizontalDashLineKeys[0]],
        this.denselandmark[
          eyebrowUpperLineKeys[eyebrowUpperLineKeys.length - 2]
        ]
      ];
      const line2 = [
        this.denselandmark[eyebrowUpperLineKeys[0]],
        this.denselandmark[eyebrowHorizontalDashLineKeys[2]]
      ];
      const line3 = [
        this.denselandmark[eyelidUpperLineKeys[eyelidUpperLineKeys.length - 2]],
        this.denselandmark[eyelidUpperLineKeys[eyelidUpperLineKeys.length - 1]]
      ];
      const line4 = [
        this.denselandmark[eyelidLowerLineKeys[eyelidLowerLineKeys.length - 2]],
        this.denselandmark[eyelidUpperLineKeys[eyelidUpperLineKeys.length - 1]]
      ];

      this.$emit(
        "_createGradualLine",
        [line1, line2, line3, line4],
        l,
        whiteLineAnimate.delay,
        whiteLineAnimate.dura
      );
    },
    drawEyeBrowDashLineAndText() {
      // const { denselandmark, data, number } = this.props;
      const {
        dashLineAnimate,
        textGroupAnimate
      } = animateControl.timerControl[2].detail;
      const radius = animateControl.animateConfig.arc.radius * fs;
      const startX = (allowEyeRect.left - 0.3) * fs;
      const endX = (allowEyeRect.left + allowEyeRect.width + 0.3) * fs;
      // 上中下水平虚线
      const dashline1 = [
        {
          x: startX,
          y: this.denselandmark[eyebrowHorizontalDashLineKeys[0]].y
        },
        { x: endX, y: this.denselandmark[eyebrowHorizontalDashLineKeys[0]].y }
      ];
      const dashline2 = [
        {
          x: startX,
          y: this.denselandmark[eyebrowHorizontalDashLineKeys[1]].y
        },
        { x: endX, y: this.denselandmark[eyebrowHorizontalDashLineKeys[1]].y }
      ];
      const dashline3 = [
        {
          x: startX,
          y: this.denselandmark[eyebrowHorizontalDashLineKeys[2]].y
        },
        { x: endX, y: this.denselandmark[eyebrowHorizontalDashLineKeys[2]].y }
      ];
      // 左右垂直虚线
      let left = eyebrowKeys[0];
      let right = eyebrowKeys[0];
      for (let key of eyebrowKeys) {
        if (this.denselandmark[key].x < this.denselandmark[left].x) {
          left = key;
        }
        if (this.denselandmark[key].x > this.denselandmark[right].x) {
          right = key;
        }
      }
      const topY =
        Math.min(
          dashline1[0].y,
          dashline2[0].y,
          dashline3[0].y,
          this.denselandmark[left].y,
          this.denselandmark[right].y
        ) -
        0.25 * fs;
      const bottomY =
        Math.max(
          dashline1[0].y,
          dashline2[0].y,
          dashline3[0].y,
          this.denselandmark[left].y,
          this.denselandmark[right].y
        ) +
        0.1 * fs;
      const dashline4 = [
        { x: this.denselandmark[left].x, y: topY },
        { x: this.denselandmark[left].x, y: bottomY }
      ];
      const dashline5 = [
        { x: this.denselandmark[right].x, y: topY },
        { x: this.denselandmark[right].x, y: bottomY }
      ];
      this.$emit(
        "_createDashLine",
        [dashline1, dashline2, dashline3, dashline4, dashline5],
        dashLineAnimate.delay
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,
        dashline1[0],
        dashline3[0],
        `眉毛高度\n${this.data.eyebrow.brow_height || 0}mm`,
        { vertical: "vertical" },
        textGroupAnimate
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,
        { x: endX - 0.2 * fs, y: dashline1[0].y },
        { x: endX - 0.2 * fs, y: dashline2[0].y },
        `眉毛粗细\n${this.data.eyebrow.brow_thick || 0}mm`,
        { vertical: "vertical", writePosition: "right" },
        textGroupAnimate
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,
        { x: this.denselandmark[left].x, y: topY },
        { x: this.denselandmark[right].x, y: topY },
        `眉毛宽度\n${this.data.eyebrow.brow_width || 0}mm`,
        { writePosition: "top" },
        textGroupAnimate
      );
      // 角度上
      this.$emit(
        "_createArcWithText",
        {
          start: dashline1[1],
          center: this.denselandmark[eyebrowHorizontalDashLineKeys[0]],
          end: this.denselandmark[eyebrowHorizontalDashLineKeys[2]]
        },
        `${this.data.eyebrow.brow_uptrend_angle || 0}°`,
        { x: radius * 1.5, y: 0 },
        { delay: dashLineAnimate.delay, size: 2, radius },
        { delay: dashLineAnimate.delay, size: 2 }
      );
      // 角度下
      this.$emit(
        "_createArcWithText",
        {
          start: this.denselandmark.left_eyebrow_0,
          center: this.denselandmark.left_eyebrow_34,
          end: dashline3[0]
        },
        `${this.data.eyebrow.brow_camber_angle || 0}°`,
        { x: -radius * 1.5, y: 0 },
        { delay: dashLineAnimate.delay, size: 2, radius },
        { delay: dashLineAnimate.delay, size: 2 }
      );
    },
    drawEyelid() {
      // const { denselandmark, data, number } = this.props;
      const {
        dashLineAnimate,
        textGroupAnimate
      } = animateControl.timerControl[2].detail;
      const radius = animateControl.animateConfig.arc.radius * fs;
      // 角度
      this.$emit(
        "_createArcWithText",
        {
          start: this.denselandmark[
            eyelidLowerLineKeys[eyelidLowerLineKeys.length - 2]
          ],
          center: this.denselandmark[
            eyelidUpperLineKeys[eyelidUpperLineKeys.length - 1]
          ],
          end: this.denselandmark[
            eyelidUpperLineKeys[eyelidUpperLineKeys.length - 2]
          ]
        },
        `${this.data.eyes.angulus_oculi_medialis || 0}°`,
        { x: -radius * 1.5, y: -radius },
        { delay: dashLineAnimate.delay, radius },
        { delay: dashLineAnimate.delay }
      );

      let max = eyelidKeys[0],
        min = eyelidKeys[0],
        left = eyelidKeys[0],
        right = eyelidKeys[0];
      for (let key of eyelidKeys) {
        if (this.denselandmark[key].y < this.denselandmark[min].y) {
          min = key;
        }
        if (this.denselandmark[key].y > this.denselandmark[max].y) {
          max = key;
        }
        if (this.denselandmark[key].x < this.denselandmark[left].x) {
          left = key;
        }
        if (this.denselandmark[key].x > this.denselandmark[right].x) {
          right = key;
        }
      }
      // 水平横线
      const startX = (allowEyeRect.left + 0.15) * fs;
      const endX = (allowEyeRect.left + allowEyeRect.width + 0.1) * fs;
      const topY = this.denselandmark[min].y - 0.1 * fs;
      const BottomY = (allowEyeRect.top + allowEyeRect.height + 0.3) * fs;
      const dashline1 = [
        { x: startX, y: this.denselandmark[min].y },
        { x: endX, y: this.denselandmark[min].y }
      ];
      const dashline2 = [
        { x: startX, y: this.denselandmark[max].y },
        { x: endX, y: this.denselandmark[max].y }
      ];
      // 垂直虚线
      const dashline3 = [
        { x: this.denselandmark[left].x, y: topY },
        { x: this.denselandmark[left].x, y: BottomY }
      ];
      const dashline4 = [
        { x: this.denselandmark[right].x, y: topY },
        { x: this.denselandmark[right].x, y: BottomY }
      ];
      this.$emit(
        "_createDashLine",
        [dashline1, dashline2, dashline3, dashline4],
        dashLineAnimate.delay
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,
        dashline1[0],
        dashline2[0],
        `眼睛高度\n${this.data.eyes.eye_height}mm`,
        { vertical: "vertical" },
        textGroupAnimate
      );
      this.$emit(
        "makeTextWidthArrowLine",
        this.draw,
        dashline3[1],
        dashline4[1],
        `眼睛宽度\n${this.data.eyes.eye_width}mm`,
        {},
        textGroupAnimate
      );
      // // 瞳孔垂线
      // const dashline6 = [
      //     { x: denselandmark.left_eye_pupil_center.x, y: topY - 0.05 * fs },
      //     { x: denselandmark.left_eye_pupil_center.x, y: BottomY + 0.4 * fs }
      // ]
      // const dashline5 = [
      //     { x: denselandmark.left_eye_pupil_radius.x, y: topY - 0.05 * fs },
      //     { x: denselandmark.left_eye_pupil_radius.x, y: BottomY + 0.4 * fs }
      // ]
      // this.props._createDashLine(
      //     [dashline5, dashline6],
      //     pupilAnimate.dashlineDelay
      // )
      // this.props._makeTextWidthArrowLine(
      //     this.props.draw,
      //     dashline5[1],
      //     dashline6[1],
      //     `瞳孔半径\n${denselandmark.left_eye_pupil_radius_number}mm`,
      //     {},
      //     { delay: pupilAnimate.textDelay }
      // )
    },
    createPath(keys, color, size, animate) {
      // const { denselandmark, theme } = this.props;
      let paths = [];
      for (let key of keys) {
        paths.push(this.denselandmark[key]);
      }
      let path = makePath(this.draw, paths, {
        color: color || themeConfig[this.theme].arrowLineColor,
        size: size || 0.01 * fs
      });
      if (animate) {
        path
          .css(animate.startcss)
          .animate(animate.dura, animate.delay)
          .css(animate.endcss);
      }
      return paths;
    }
  }
};
</script>
